import React, { useState, useEffect } from 'react';
import { Container, Row, Col, ListGroup, Card, Badge, Accordion, Form, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux'
import { StatusBadge, Responsible } from './misc'
import { TaskList } from './taskdetails'
import { ConfirmationModal } from '../common/confirmationmodal'
import { deleteProject } from '../actions'

export function ProjectDetails() {

	const project = useSelector((state) => state.project.selected)
	const tobedeleted = useSelector((state) => state.project.tobedeleted)
	const dispatch = useDispatch()
	const tasks = project === null ? null : project.tasks
	const token = useSelector((state) => state.authorization.token)
	
	const deletetext = 'Vahvista projektin "' + project.name + '" poistaminen'
		
	return (
		<>
		<ConfirmationModal show={tobedeleted !== null} title="Projektin poistaminen" text={deletetext} onCancel={() => dispatch({type: 'project/delete', payload: null})} onOk={() => dispatch(deleteProject(token, tobedeleted))} />
		<ProjectHeader project={project} />
		<TaskList tasks={tasks}/>
		</>
	);
}

function ProjectHeader({project}) {
	
	const companyId = useSelector((state) => state.dashboard.companyId)
	const dispatch = useDispatch()
	
	const style={
		'border': '1px solid #ced4da',
		'borderRadius': '0.375rem',
		'fontSize': '1rem',
		'padding': '0.375rem 0.75rem',
		'minHeight': '2rem'
	};
	
	const labelstyle = {
		'fontSize': '12px',
		'fontWeight': '700',
		'margin': 'auto 0px'
	}

	if(companyId === null) {
		return <div>Valitse yritys...</div>
	} else if(project === null) {
		return <div>Valitse projekti...</div>
	} else {
		return (
			<Card className='mb-2'>
				<Card.Header>
					<Container>
						<Row>
							<Col className="me-auto">{project.name}</Col>
							<Col xs='auto'><StatusBadge task={project} /></Col>
						</Row>
					</Container>
				</Card.Header>
			<Card.Body>
			<Row>
				<Col style={labelstyle} md={2}>Aloitus</Col><Col style={style}>{project.begin}</Col>
				<Col style={labelstyle} md="auto">Tavoiteaika</Col><Col style={style}>{project.deadline}</Col>
			</Row>
			<Row className="mt-2">
				<Col style={labelstyle} md={2}>Vastuuhenkilö</Col><Col style={style}><Responsible task={project} /></Col>
			</Row>
			<Row className="mt-2">
				<Col style={labelstyle} md={2}>Projektiryhmä</Col><Col style={style}>{project.team}</Col>
			</Row>
			<Row className="mt-2"><Col style={labelstyle} md={2}>Havaittu haaste</Col><Col style={style}>{project.description}</Col></Row>
			<Row className="mt-2"><Col style={labelstyle} md={2}>Lähtötilanne</Col><Col style={style}>{project.start}</Col></Row>
			<Row className="mt-2"><Col style={labelstyle} md={2}>Mitattava tavoite</Col><Col style={style}>{project.measure}</Col></Row>
			</Card.Body>
			<Card.Footer>
				<Container>
					<Row>
						<Col className="ms-auto pe-0" md="auto"><Button size="sm" variant="danger" onClick={() => dispatch({type: 'project/delete', payload: project})}>Poista projekti</Button></Col>
						<Col className="pe-0" md="auto"><Button size="sm" onClick={() => dispatch({type: 'task/edit', payload: { companyId: project.companyId, parentId: project.projectId }})}>Lisää tehtävä</Button></Col>
						<Col className="pe-0" md="auto"><Button size="sm" onClick={() => dispatch({type: 'project/edit', payload: project})}>Muokkaa</Button></Col>
					</Row>
				</Container>
			</Card.Footer>
			</Card>
		);
	}
}

