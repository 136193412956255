import react, { useState, useEffect, useRef } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { postReportDetails } from '../actions'
import { DrawXAxisDetails, DrawYAxis, DrawData, DrawIndex } from "./graphutil"


function GraphImage() {
  	
  	const svgRef = useRef(null);
  	const details = useSelector((state) => state.report.details)
  	const average = useSelector((state) => state.report.average)
  	
  	useEffect(() => {
		if(details === null) {
			/* ignore */
		} else {
			DrawXAxisDetails(svgRef, 'detail', details, 80, 290);
			DrawYAxis(svgRef, details, 80, 290);
			DrawData(svgRef, details, 80, 290);
			DrawIndex(svgRef, 50, average);
		}
	}, [details])

  	
  	
	if(details) {
	return (
		<svg className="d-block mx-auto" viewBox="-300,-300,600,600" strokeLinejoin="round" strokeLinecap="round" height="100%" ref={svgRef} style={{ background: "white" }} >
			<g className="xaxis"/>
			<g className="yaxis"/>
			<g className="index"/>
			<g className="data" />
   		</svg>); 
	} else {
		return <Spinner animation="grow" />
	}
}


export function GraphModal({show, onClose}) {

  	const dispatch = useDispatch()
  	const token = useSelector((state) => state.authorization.token)
  	const language = useSelector((state) => state.authorization.language)
  	const surveyId = useSelector((state) => state.report.surveyId)
  	const name = useSelector((state) => state.report.name)

  	
  	useEffect(() => {
		if(token !== null && surveyId !== null && show) {
			dispatch(postReportDetails(token, surveyId, language))
		}
	}, [token, surveyId, show])
  	
	return (
		<Modal show={show} fullscreen onHide={onClose}>
		<Modal.Header closeButton>
			<Modal.Title>{name}</Modal.Title>
        </Modal.Header>
		<Modal.Body className="bg-light">
			<GraphImage />
		</Modal.Body>
		</Modal>
	);
}
