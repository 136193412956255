import React from 'react'
import { useSelector } from 'react-redux'
import Signup from "./signupview"
import ReportView from './reportview'
import ProjectView from './projectview'
import DashboardView from './dashboardview'
import AdminUserView from './auserview'
import PasswordResetView from './common/passwordreset'
import QuestionMgmtView from './questionmgmtview'
import QuestionnaireView from './questionnaireview'

import { BrowserRouter, Routes, Route } from 'react-router-dom'

function RootView() {
	
	const scope = useSelector((state) => state.authorization.scope)
	
	if(scope === 'admin') {
		return <DashboardView admin={true} />
	} else if(scope === 'p_company') {
		return <ProjectView />
	} else {
		return <DashboardView admin={false} />
	}
}


export default function App() {

  return (
    <div className="app">
    <BrowserRouter>
    	<Routes>
			<Route path="/" action={() => console.log('Route.RootView')} element={<RootView />} />
			<Route path="/qmgmt" action={() => console.log('Route.QuestionMgmtView')} element={<QuestionMgmtView />} />
			<Route path="/login" action={() => console.log('Route.RootView')} element={<RootView />} />
			<Route path="/users" action={() => console.log('Route.AdminUserView')} element={<AdminUserView />} />
			<Route path="/company" action={() => console.log('Route.Signup')} element={<Signup />} />
			<Route path="/report" action={() => console.log('Route.ReportView')} element={<ReportView />} />
			<Route path="/project" action={() => console.log('Route.ProjectView')} element={<ProjectView />} />
			<Route path="/reset/:url" action={() => console.log('Route.PasswordResetView')} element={<PasswordResetView/>} /> 
			<Route path="/questionnaire/:token" action={() => console.log('Route.QuestionnaireView')} element={<QuestionnaireView/>} />
    	</Routes>
    </BrowserRouter>
    </div>
  );
}


