import react, { useState } from 'react'
import { Modal, Container, Row, Col, Button, FloatingLabel, Form, Spinner } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { getSurveyStatus, postParticipantEmailSent } from '../actions'

export function PasswordResetModal() {
	
	const dispatch = useDispatch()
	const token = useSelector((state) => state.authorization.token)
	const passwordreset = useSelector((state) => state.admin.passwordreset)

	const pressOK = () => {
		dispatch({ type: 'admin/passwordreset', payload: null});
	}
	
	const details = passwordreset == null ? { email: '', url: '' } : { email: '', url: '', ...passwordreset } ;
	
	const email = details.email;
	const url = details.url;
	const wait = passwordreset == null ? false : passwordreset.wait;
	
	const copyToClipboard = value => {
		console.log(value);
		navigator.clipboard.writeText(value);
	}

	return (
		<Modal size="xl" show={passwordreset !== null} backdrop="static">
		<Modal.Header>Password Reset Link for Admin user</Modal.Header>
		<Modal.Body className="bg-light">
			<Form.Group className="mb-3" controlId="email">
        		<Form.Label>Email</Form.Label>
        		{ wait && <div style={{ height: '40px' }}><Spinner animation="grow" /></div>}
        		{ !wait && <Form.Control readOnly type="text" value={email} onClick={() => copyToClipboard(email)} /> }
        		<Form.Text className="text-muted">
          		Tähän sähköpostiosoitteeseen tulee lähettää oheinen linkki. (kenttää napauttamalla sisältö kopioituu leikepöydälle)
        		</Form.Text>
      		</Form.Group>

      		<Form.Group className="mb-3" controlId="link">
        		<Form.Label>Linkki</Form.Label>
        		{ wait && <div style={{ height: '120px' }}><Spinner animation="grow" /></div>}
        		{ !wait && <Form.Control as="textarea" readOnly style={{ height: '120px' }} value={url} onClick={() => copyToClipboard(url)}  /> }
        		<Form.Text className="text-muted">
          		Tämä linkki tulee sisällyttää täydellisenä lähetettävään viestiin. (kenttää napauttamalla sisältö kopioituu leikepöydälle)
        		</Form.Text>
      		</Form.Group>
		</Modal.Body>
		<Modal.Footer>
			<Button onClick={() => pressOK()} variant="primary">OK</Button>
		</Modal.Footer>
		</Modal>
	)
	
}