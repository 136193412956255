import React, { useEffect, useReducer } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, Form, Row, Col, Button, Spinner } from "react-bootstrap"
import { DatePicker } from 'react-widgets';

import { initialProjectState, projectReducer} from './projectreducer'
import { postProject } from '../actions'
import { ResponsibleOptions } from './misc'
import { parseDate} from '../common/parsedate';


function ProjectDetail({state, dispatch}) {
	return (
		<Form>
			<Form.Group className="mb-3" controlId="project-name">
        		<Form.Label>Projektin nimi</Form.Label>
        		<Form.Control type="text" value={state.name} isInvalid={state.validation && !state.namevalid} onChange={(e) => dispatch({type: 'name', payload: {name: e.target.value}})} />
        		<Form.Control.Feedback type="invalid">Nimi on liian lyhyt, liian pitkä tai puuttuu</Form.Control.Feedback>
      		</Form.Group>

			<Row>
			<Col>
			<Form.Group className="mb-3" controlId="project-begin">
        		<Form.Label>Aloitus</Form.Label>
				<DatePicker parse={parseDate} value={state.begin} onChange={(e) => dispatch({type: 'begin', payload: {begin: e}})}/>
      		</Form.Group>
      		</Col>
      		<Col>

			<Form.Group className="mb-3" controlId="project-deadline">
        		<Form.Label>Tavoiteaika</Form.Label>
				<DatePicker parse={parseDate} value={state.deadline} onChange={(e) => dispatch({type: 'deadline', payload: {deadline: e} })}/>
      		</Form.Group>
      		</Col>
      		</Row>

			<Form.Group className="mb-3" controlId="project-team">
        		<Form.Label>Vastuuhenkilö</Form.Label>
        		{ false && <Form.Control type="text" value={state.responsible} onChange={(e) => dispatch({type: 'responsible', payload: {responsible: e.target.value}})} /> }
        		<Form.Select value={state.responsible} isInvalid={state.validation && !state.responsiblevalid}   onChange={(e) => dispatch({type: 'responsible', payload: {responsible: e.target.value}})}>
        			{ state.responsible === '0' && <option key="0" value="0">...valitse vastuuhenkilö...</option> }
        			<ResponsibleOptions />
        			<option key="-1" value="-1">...lisää uusi henkilö...</option>
        		</Form.Select>
        		<Form.Control.Feedback type="invalid">Vastuuhenkilö puuttuu</Form.Control.Feedback>
      		</Form.Group>

			{ state.responsible == '-1' && <div className="mb-3" style={{border: '2px dotted var(--bs-gray-400)', borderRadius: '0.375rem', padding: '0.375rem 0.75rem'}}> 

			<Form.Group className="mb-3" controlId="project-problem">
        		<Form.Label>Lisättävän vastuuhenkilön nimi</Form.Label>
        		<Form.Control type="text" value={state.responsibleUsername} isInvalid={state.validation && !state.responsibleUsernamevalid}  onChange={(e) => dispatch({type: 'responsibleUsername', payload: {responsibleUsername: e.target.value}})} />
        		<Form.Control.Feedback type="invalid">Nimi on liian lyhyt, liian pitkä tai puuttuu</Form.Control.Feedback>
      		</Form.Group>

			<Form.Group className="mb-3" controlId="project-problem">
        		<Form.Label>Lisättävän vastuuhenkilön sähköpostiosoite</Form.Label>
        		<Form.Control type="text" value={state.responsibleEmail} isInvalid={state.validation && !state.responsibleEmailvalid}  onChange={(e) => dispatch({type: 'responsibleEmail', payload: {responsibleEmail: e.target.value}})} />
        		<Form.Control.Feedback type="invalid">Sähköpostiosoite on väärän muotoinen</Form.Control.Feedback>
      		</Form.Group>

			</div> } 


			<Form.Group className="mb-3" controlId="project-problem">
        		<Form.Label>Havaittu haaste</Form.Label>
        		<Form.Control type="text" as="textarea" rows={2} value={state.description} isInvalid={state.validation && !state.descriptionvalid}  onChange={(e) => dispatch({type: 'description', payload: {description: e.target.value}})} />
        		<Form.Control.Feedback type="invalid">Haaste on liian lyhyt, liian pitkä tai puuttuu</Form.Control.Feedback>
      		</Form.Group>

			<Form.Group className="mb-3" controlId="project-start">
        		<Form.Label>Lähtötilanne</Form.Label>
        		<Form.Control type="text" as="textarea" rows={2} value={state.start} isInvalid={state.validation && !state.startvalid} onChange={(e) => dispatch({type: 'start', payload: {start: e.target.value}})} />
        		<Form.Control.Feedback type="invalid">Lähtötilanne on liian lyhyt, liian pitkä tai puuttuu</Form.Control.Feedback>
      		</Form.Group>

			<Form.Group className="mb-3" controlId="project-measure">
        		<Form.Label>Mitattava tavoite</Form.Label>
        		<Form.Control type="text" as="textarea" rows={2} value={state.measure} isInvalid={state.validation && !state.measurevalid} onChange={(e) => dispatch({type: 'measure', payload: {measure: e.target.value}})} />
         		<Form.Control.Feedback type="invalid">Tavoite on liian lyhyt, liian pitkä tai puuttuu</Form.Control.Feedback>
     		</Form.Group>

		</Form>
	)
}

function ProjectModalBody({show, onClose, title, project}) {
	
  	const [state, reactDispatch] = useReducer(projectReducer, initialProjectState(null));
 	const token = useSelector((state) => state.authorization.token)
	const dispatch = useDispatch()


	const okDisabled = state.validation && !state.valid;
  	
  	useEffect(() => {
		if(project != null) {
			reactDispatch({type: 'state', payload: initialProjectState(project)});
		} else {
			reactDispatch({type: 'init', payload: initialProjectState(null)});
		}
	}, [project])
	
	const onSave = () => {
		
		if(state.valid) {
			const params = {
				...state,
				begin: state.begin.toISOString().split("T")[0],
				deadline: state.deadline.toISOString().split("T")[0],
			}
			dispatch(postProject(token, params));
			onClose();
		} else {
			reactDispatch({type:'validation', payload: {validation: true} });
		}
	}
	
	return (
		<Modal show={show} size="lg" onHide={onClose}>
		<Modal.Header closeButton>
			<Modal.Title>{title}</Modal.Title>
        </Modal.Header>
		<Modal.Body className="bg-light">
			<ProjectDetail state={state} dispatch={reactDispatch} />
		</Modal.Body>
		<Modal.Footer>
		<Button variant="secondary" onClick={onClose} >Cancel</Button>
		<Button disabled={okDisabled} onClick={onSave} >OK</Button>
		</Modal.Footer>
		</Modal>
		
	)
}

export function ProjectModal() {
	
	const edit = useSelector((state) => state.project.edit)
	const responsibles = useSelector((state) => state.project.responsibles)
	 const dispatch = useDispatch()
	
	const show = edit != null;
	const title = edit != null && edit.projectId != null ? 'Muokkaa projektia' : 'Uusi projekti';
	
	
	return <ProjectModalBody show={show} project={edit} responsibles={responsibles} onClose={() => dispatch({type: 'project/edit', payload: null})} title={title}/>
}

