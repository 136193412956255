import * as api from '../api/'

export const postReport = (token, surveyId, compareId, language) => {
	return dispatch => {
		dispatch({ type: 'report/select', payload: {surveyId: surveyId, compareId: compareId} });
		api.postReport(token, surveyId, compareId, language)
		.then(resp => {
			dispatch({
				type: 'report/store',
				payload: resp.data
			});
		})
		.catch(e => console.log(e));
	}
} 

export const postReportDetails = (token, surveyId, language) => {
	return dispatch => {
		api.postReportDetails(token, surveyId, language)
		.then(resp => {
			dispatch({
				type: 'report/details',
				payload: resp.data
			});
		})
		.catch(e => console.log(e));
	}
} 

