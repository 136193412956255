import React from 'react'
import { Row, Col, ButtonGroup, Button } from 'react-bootstrap'


function languageNA(language) {
	switch(language) {
		case 'et':
			return 'Ma ei saa öelda';
		case 'lv':
			return 'Es nevaru pateikt';
		case 'lt':
			return 'Negaliu pasakyti';
		case 'en':
			return 'Don\'t know';
		default:
			return 'En osaa sanoa';
	}
}

function languageAgree(language) {
	switch(language) {
		case 'fi':
			return 'Täysin samaa mieltä';
		default:
			return 'Fully Agree';
	}
}

function languageDisagree(language) {
	switch(language) {
		case 'fi':
			return 'Täysin eri mieltä';
		default:
			return 'Fully Disagree';
	}
}


export function ButtonPanelXS({selected, onSelect, language}) {
	
	const agree = '1 - ' + languageAgree(language);
	const disagree = '10 - ' + languageDisagree(language);
	
	return (
		<>
		<Row className="d-block d-md-none">
		<Button active={selected=='1'} onClick={() => onSelect('1')} variant="outline-secondary">{disagree}</Button>
		</Row>
		<Row className="d-block d-md-none">
		<Button active={selected=='2'} onClick={() => onSelect('2')} variant="outline-secondary">2</Button>
		</Row>
		<Row className="d-block d-md-none">
		<Button active={selected=='3'} onClick={() => onSelect('3')} variant="outline-secondary">3</Button>
		</Row>
		<Row className="d-block d-md-none">
		<Button active={selected=='4'} onClick={() => onSelect('4')} variant="outline-secondary">4</Button>
		</Row>
		<Row className="d-block d-md-none">
		<Button active={selected=='5'} onClick={() => onSelect('5')} variant="outline-secondary">5</Button>
		</Row>
		<Row className="d-block d-md-none">
		<Button active={selected=='6'} onClick={() => onSelect('6')} variant="outline-secondary">6</Button>
		</Row>
		<Row className="d-block d-md-none">
		<Button active={selected=='7'} onClick={() => onSelect('7')} variant="outline-secondary">7</Button>
		</Row>
		<Row className="d-block d-md-none">
		<Button active={selected=='8'} onClick={() => onSelect('8')} variant="outline-secondary">8</Button>
		</Row>
		<Row className="d-block d-md-none">
		<Button active={selected=='9'} onClick={() => onSelect('9')} variant="outline-secondary">9</Button>
		</Row>
		<Row className="d-block d-md-none">
		<Button active={selected=='10'} onClick={() => onSelect('10')} variant="outline-secondary">{agree}</Button>
		</Row>
		<Row className="d-block d-md-none">
		<Button active={selected=='0'} onClick={() => onSelect('0')} variant="outline-secondary">{languageNA(language)}</Button>
		</Row>
		</>
	)
}


export function ButtonPanelMedium({selected, onSelect, language}) {
	
	const agree = languageAgree(language);
	const disagree = languageDisagree(language);
	
	return (
		<>
	    <Row className="d-none d-md-flex mt-5">
	    	<Col md={1} />
	    	<Col md="auto" className="ms-2 me-auto">
	    		<Row>
	    			<ButtonGroup aria-label="Selection group">
						<Button active={selected=='1'} onClick={() => onSelect('1')} size="lg" className="me-1" variant="outline-secondary">1</Button>
						<Button active={selected=='2'} onClick={() => onSelect('2')} size="lg" className="me-1" variant="outline-secondary">2</Button>
						<Button active={selected=='3'} onClick={() => onSelect('3')} size="lg" className="me-1" variant="outline-secondary">3</Button>
						<Button active={selected=='4'} onClick={() => onSelect('4')} size="lg" className="me-1" variant="outline-secondary">4</Button>
						<Button active={selected=='5'} onClick={() => onSelect('5')} size="lg" className="me-1" variant="outline-secondary">5</Button>
						<Button active={selected=='6'} onClick={() => onSelect('6')} size="lg" className="me-1" variant="outline-secondary">6</Button>
						<Button active={selected=='7'} onClick={() => onSelect('7')} size="lg" className="me-1" variant="outline-secondary">7</Button>
						<Button active={selected=='8'} onClick={() => onSelect('8')} size="lg" className="me-1" variant="outline-secondary">8</Button>
						<Button active={selected=='9'} onClick={() => onSelect('9')} size="lg" className="me-1" variant="outline-secondary">9</Button>
						<Button active={selected=='10'} onClick={() => onSelect('10')} size="lg" variant="outline-secondary">10</Button>
					</ButtonGroup>
				</Row>
				<Row>
					<Col className="ms-3" style={{'textAlign': 'left'}}><small className="text-muted">{disagree}</small></Col>
					<Col className="me-3" style={{'textAlign': 'right'}}><small className="text-muted">{agree}</small></Col>
				</Row>
			</Col>
			<Col md="auto" className="ms-auto me-2">
					<ButtonGroup aria-label="Dont know group">
        				<Button active={selected=='0'} onClick={() => onSelect('0')} size="lg" variant="outline-secondary">{languageNA(language)}</Button>
      				</ButtonGroup>
			</Col>
			<Col md={1} />
		</Row>
		</>
	)
}
