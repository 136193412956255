import React from "react"
import { Form, Row, Col } from "react-bootstrap"


export function FinishedSurvey({survey}) {
	const id = survey.surveyId
	const timerange = survey.startDate + ' - ' + survey.finishDate

	return(
	<>
		<Row className="mb-2">
		<Form.Group as={Col} controlId={'name-'+id}>
			<Form.Label>Nimi</Form.Label>
			<Form.Control value={survey.name} readOnly />		
		</Form.Group>
		<Form.Group as={Col} controlId={'deadline-'+id}>
			<Form.Label>Ajankohta</Form.Label>
			<Form.Control value={timerange} readOnly />		
		</Form.Group>
		</Row>
		<Row className="mb-4">
		<Form.Group as={Col} controlId={'participants-'+id}>
			<Form.Label>Osallistujia</Form.Label>
			<Form.Control value={survey.participantsAll} readOnly />		
		</Form.Group>
		</Row>
	</>
	);
}
