import React, { useState } from "react"
import { Button, Form } from "react-bootstrap"
import { useSelector, useDispatch } from 'react-redux';
import { postEmailAttachment } from '../actions'

export function FileDrop({companyId, surveyId, admin}) {
	
	const dispatch = useDispatch();
	const token = useSelector((state) => state.authorization.token)

  const onDragOver = (event) => {
    event.stopPropagation();
    event.preventDefault();
  }

  const onDragEnter = (event) => {
    event.stopPropagation();
    event.preventDefault();
  }

  const onFileDrop = (companyId, surveyId, admin) => {
    event.stopPropagation();
    event.preventDefault();

	console.log('onFileDrop ' + surveyId);

    const files = event.dataTransfer.files;
    const metadata = [];
    for (var i = 0; i < files.length; i++) {
      const file = files[i];
      metadata.push({
        name: file.name,
        type: file.type,
        size: file.size,
        modified: file.lastModified,
        file: file
      })
	  dispatch(postEmailAttachment(token, companyId, surveyId,file.type, file));		  
    }
  }
  

  const onChange = (event, companyId, surveyId, admin) => {
	event.stopPropagation();
    event.preventDefault();

	console.log('onChange ' + surveyId);
    const files = event.target.files;
    const metadata = [];
    for (var i = 0; i < files.length; i++) {
		const file = files[i];
		metadata.push({
			name: file.name,
			type: file.type,
			size: file.size,
			modified: file.lastModified,
			file: file
      	})
	  	dispatch(postEmailAttachment(token, companyId, surveyId,file.type, file));
    }
  }

	const onClick = (selectedFileId) => {
		const element = document.getElementById(selectedFileId);
		element.click();
	}


	const selectFileId = 'selectedFile-' + surveyId;

	return (
		<Form.Group onDragEnter={onDragEnter} onDragOver={onDragOver} onDrop={() => onFileDrop(companyId, surveyId, admin)}>
        <Form.Control id={selectFileId} size="sm" style={{'display': 'none'}} onChange={(event) => onChange(event, companyId, surveyId, admin)} type="file" />
		<Button className="w-100" variant="outline-secondary" size="sm"  onClick={() => onClick(selectFileId)} >Drop files here</Button>
      	</Form.Group>
	);
}