import { createSlice } from '@reduxjs/toolkit'

export const passwordSlice = createSlice({
	name: 'password',
	initialState: {
		show: false,
		error: null,
		wait: false
	},
	reducers: {
		show: (state, action) => {
			state.show = true
			state.error = null
			state.wait = false
		},
		error: (state, action) => {
			state.show = true
			state.error = action.payload
			state.wait = false
		},
		success: (state, action) => {
			state.show = false
			state.error = null
			state.wait = false
		},
		wait: (state, action) => {
			state.show = true
			state.error = null
			state.wait = true
		}
	}
})

export const { show, wait, error, success } = passwordSlice.actions;

export default passwordSlice.reducer