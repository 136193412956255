import axios from 'axios'

export function ApiClient(token, headers) {

const API_BASE_URL = 'https://hrci-p1.hrci.io'

	if(token === null) {
		return axios.create({
			baseURL : API_BASE_URL,
			headers: { 
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			...headers
			}
		});
	} else {	
		return axios.create({
			baseURL : API_BASE_URL,
			headers: { 
			'Content-Type': 'application/json',
			'Accept': 'application/json',
			'Authorization': 'Bearer ' + token,
			...headers
			}
		});
	}	
	
}	
