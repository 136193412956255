import React, { useEffect, useReducer } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, Form, Row, Col, Button, OverlayTrigger, Tooltip } from "react-bootstrap"
import { postProject } from '../actions'
import { responsibleName } from './misc'


function Range({value, onChange}) {
	return (
		<>
		<OverlayTrigger
          placement='right'
          overlay={<Tooltip>{value}%</Tooltip>}>
       		<Form.Range value={value} onChange={onChange} />
        </OverlayTrigger>
		</>
	)
}

function TaskStatusDetail({task, state, dispatch}) {

	const begin = task == null ? '' : task.begin
	const deadline = task == null ? '' : task.deadline
	const description = task == null || task.description == null ? '' : task.description
	const responsible = task == null ? '' : responsibleName(task)

	return (
		<Form>
		<Row className="mb-3">
			<Col md={2}><Form.Label >Aloitus</Form.Label></Col>
       		<Col md={4}><Form.Control disabled readOnly type="text" value={begin} /></Col>
			<Col md={2}><Form.Label>Tavoiteaika</Form.Label></Col>
       		<Col md={4}><Form.Control disabled readOnly type="text" value={deadline} /></Col>
		</Row>
		<Row className="mt-2">
			<Col md={2}><Form.Label >Vastuuhenkilö</Form.Label></Col>
       		<Col md={10}><Form.Control disabled readOnly type="text" value={responsible} /></Col>
		</Row>
		<Row className="mt-2">
			<Col md={2}><Form.Label >Tehtävän kuvaus</Form.Label></Col>
       		<Col md={10}><Form.Control disabled readOnly type="text" as="textarea" rows={2} value={description} /></Col>
		</Row>
		<Row className="mt-2">
			<Col md={2}><Form.Label >Valmiusaste</Form.Label></Col>
       		<Col md={10}>
       			<Range value={state.state} onChange={(e) => dispatch({type: 'state', payload: {state: e.target.value}})} />
       		</Col>
		</Row>
		<Row className="mt-2">
			<Col md={2}><Form.Label >Tilanne</Form.Label></Col>
       		<Col md={10}><Form.Control type="text" as="textarea" rows={2} value={state.status} onChange={(e) => dispatch({type: 'status', payload: {status: e.target.value}})} /></Col>
		</Row>
		{ false && task.tasks && <SubTasks tasks={task.tasks}  /> }
		</Form>
	)
}

function initialState(task) {
	
	if(task == null) {
		return { 
			projectId: null,
			companyId: null,
			state: 0,
			status: ''
		}
	} else {
		return {
		projectId: task.projectId,
		companyId: task.companyId,
		state: task.state,
		status: task.status == null ? '' : task.status
		}
	}
}

function reducer(state, action) {

	const oldvalue = action.payload == null || action.payload.state == null ? state.state : Math.round(action.payload.state);
	const value = Math.round((4 + oldvalue) / 10) * 10

	const newstate = { ...state, ...action.payload, state: value};
	
	/* console.log('taskStateReducer', action.payload, newstate); */
	
	return newstate;	
}


export function TaskStatusModal({task, onClose}) {
	
	const [state, reactDispatch] = useReducer(reducer, initialState(null));
 	const token = useSelector((state) => state.authorization.token)
	const dispatch = useDispatch()
	
	useEffect(() => {
		reactDispatch({type: 'init', payload: initialState(task)})
	}, [task])
	
	const show = task != null;
	
	const onSave = () => {
		dispatch(postProject(token, state));		
		onClose();
	}
	
	return (
		<Modal show={show} size="lg" onHide={onClose}>
		<Modal.Header closeButton>
			<Modal.Title>Päivitä tilanne</Modal.Title>
        </Modal.Header>
		<Modal.Body className="bg-light">
			<TaskStatusDetail task={task} state={state} dispatch={reactDispatch} />
		</Modal.Body>
		<Modal.Footer>
		<Button variant="secondary" onClick={onClose} >Cancel</Button>
		<Button onClick={onSave} >OK</Button>
		</Modal.Footer>

		</Modal>
	)
}