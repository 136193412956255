import { createSlice } from '@reduxjs/toolkit'

export const taskSlice = createSlice({
	name: 'task',
	initialState: {
		own: [],
		selected: null,
		edit: null
	},
	reducers: {
		wait: (state, action) => {
			state.own = null
			state.selected = null	
		},
		wait_update: (state, action) => {
			state.own = null	
		},
		list: (state, action) => {
			state.own = action.payload
			if(state.selected !== null) {
				const fnd = action.payload.flatMap(project => project.tasks).find(task => task.projectId === state.selected.projectId)
				if(fnd != null) {
					state.selected = fnd
				} else {
					state.selected = null
				}
			} 
		},
		edit: (state, action) => {
			state.edit = action.payload
		},
		select: (state, action) => {
			state.selected = action.payload
		}
	}
})

export const { wait, wait_update, list, edit, select } = taskSlice.actions;

export default taskSlice.reducer