import { createSlice } from '@reduxjs/toolkit'

export const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState: {
		companies: null,
		companyId: null,
		surveys: null,
		surveyId: null,
		participant: null,
		editsurvey: null,
	},
	reducers: {
		waitCompanies: (state, action) => {
			state.companies = null
			state.companyId = null
			state.surveys = null
			state.surveyId = null
			state.participant = null
			state.editsurvey = null
		},
		companies: (state, action) => {
			state.companies = action.payload
			state.companyId = action.payload.length === 1 ? action.payload[0].companyId : state.companyId;
			if(state.companyId !== null) {
				const comp = action.payload.find(company => company.surveys !== null);
				state.surveys = comp === null ? null : comp.surveys;
				state.companyId = comp  === null ? null : comp.companyId	
			}
		},
		selectCompany: (state, action) => {
			state.companyId = action.payload
			state.surveys = null
			state.surveyId = null
		},
		waitSurveys: (state, action) => {
			state.surveys = null
			state.editsurvey = null
		},
		selectSurvey: (state, action) => {
			state.surveyId = action.payload
		},
		participant: (state, action) => {
			state.participant = action.payload == '' ? null : action.payload
		},
		editsurvey: (state, action) => {
			state.editsurvey = action.payload
		}
	}
})

export const { waitCompanies, companies, selectCompany, surveys, waitSurveys, selectSurvey, participant, editsurvey } = dashboardSlice.actions;

export default dashboardSlice.reducer
