import React from 'react'
import { Modal, Button, Form, Spinner } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { postParticipantEmailSent } from '../actions'

export function ParticipantModal() {
	
	const dispatch = useDispatch()
	const token = useSelector((state) => state.authorization.token)
	const participant = useSelector((state) => state.dashboard.participant)
	const companyId = useSelector((state) => state.dashboard.companyId)

	const pressOK = (personId) => {
		dispatch(postParticipantEmailSent(token, companyId, null, personId));
	}
	
	const pressCancel = () => {
		dispatch({ type: 'dashboard/participant', payload: null })
	}
	
	const details = participant == null ? { email: '', url: '' } : { email: '', url: '', ...participant } ;
	
	const email = details.email;
	const url = details.url;
	const wait = participant == null ? false : participant.wait;
	
	const copyToClipboard = value => {
		console.log(value);
		navigator.clipboard.writeText(value);
	}

	return (
		<Modal size="xl" fullscreen='lg-down' show={participant !== null} backdrop="static">
		<Modal.Header>Osallistujan linkki</Modal.Header>
		<Modal.Body className="bg-light">
			<Form.Group className="mb-3" controlId="email">
        		<Form.Label>Email</Form.Label>
        		{ wait && <div style={{ height: '40px' }}><Spinner animation="grow" /></div>}
        		{ !wait && <Form.Control readOnly type="text" value={email} onClick={() => copyToClipboard(email)} /> }
        		<Form.Text className="text-muted">
          		Tähän sähköpostiosoitteeseen tulee lähettää oheinen linkki. (kenttää napauttamalla sisältö kopioituu leikepöydälle)
        		</Form.Text>
      		</Form.Group>

      		<Form.Group className="mb-3" controlId="link">
        		<Form.Label>Linkki</Form.Label>
        		{ wait && <div style={{ height: '120px' }}><Spinner animation="grow" /></div>}
        		{ !wait && <Form.Control as="textarea" readOnly style={{ height: '120px' }} value={url} onClick={() => copyToClipboard(url)}  /> }
        		<Form.Text className="text-muted">
          		Tämä linkki tulee sisällyttää täydellisenä lähetettävään viestiin. (kenttää napauttamalla sisältö kopioituu leikepöydälle)
        		</Form.Text>
      		</Form.Group>
		</Modal.Body>
		<Modal.Footer>
			<Button onClick={pressCancel} variant="secondary" >Peruuta</Button>
			<Button onClick={() => pressOK(participant.personId)} variant="primary" disabled={wait} >Vahvista lähettäneesi linkin</Button>
		</Modal.Footer>
		</Modal>
	)
	
}