import React, { useEffect, useState} from 'react'
import { Container, Row, Col, Spinner, Card, Button, Form, FloatingLabel } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { ButtonPanelXS, ButtonPanelMedium } from './buttonpanel'
import { fetchQuestion, postAnswer } from '../actions'


function CommentField({comment, setComment, language}) {
	
	const label = languageComment(language)

	return (
		<Row className="mt-5">
			<Col md={1} />
			<Col xs={12} md={10}>
				<FloatingLabel className="mb-3" controlId="comments" label={label}>
       				<Form.Control as="textarea" placeholder={label} value={comment} onChange={(evt) => setComment(evt.target.value)}  style={{ height: '6em' }} />
      			</FloatingLabel>
      		</Col>
			<Col md={1} />
		</Row>
	)
}

function languageNext(language) {
	switch(language) {
		case 'fi':
			return 'Seuraava';
		case 'et':
			return 'OK';
		case 'lv':
			return 'OK';
		case 'lt':
			return 'OK';
		default:
			return 'Next';
	}
}

function languagePrevious(language) {
	switch(language) {
		case 'fi':
			return 'Edellinen';
		default:
			return 'Previous';
	}
}

function languageComment(language) {
	switch(language) {
		case 'fi':
			return 'Vapaaehtoinen kommenttini'
		default:
			return 'Comment'
	}
}


function QuestionnaireContent({question, onNext}) {
	
	const [selected, setSelected] = useState(question.result);
	const [comment, setComment] = useState(question.comment);
	
	const onSelect = (value) => {
		if(value == selected) {
			onNext({...question, result: selected, comment: comment, nextId: question.nextId})
		} else {
			setSelected(value);
		}
	}
	
	return (
		<>
      		<h3 className="pt-3 text-center">{question.l2title}</h3>
      		<Card className="my-5 py-3">
				<Card.Body>
					<Card.Title className="text-center">{question.title}</Card.Title>
					<ButtonPanelMedium selected={selected} comment={comment} onSelect={onSelect} language={question.language} setComment={setComment} />
					<ButtonPanelXS selected={selected} comment={comment} onSelect={onSelect} language={question.language} setComment={setComment} />
					{ true && <CommentField comment={comment} language={question.language} setComment={setComment} /> }
				</Card.Body>
   			</Card>
   			<Container>
   			<Row>
   			<Col md="auto" >
   				<Button onClick={() => onNext({...question, result:selected, comment:comment, nextId: question.previousId})} disabled={question.previousId==null} variant='outline-primary' className="me-3" size="lg">{languagePrevious(question.language)}</Button>
   			</Col>
   			<Col><h5 className="text-center">{question.index}/{question.size}</h5></Col>
   			<Col md="auto" >
   				<Button onClick={() => onNext({...question, result:selected, comment:comment, nextId: question.nextId})} disabled={selected==null} variant='outline-primary' className="me-3" size="lg">{languageNext(question.language)}</Button>
   			</Col>
   			</Row>
   			</Container>
		</>	
	)
}

function ThankYou() {
	return (
		<>
		<Card className="mb-3">
          <Card.Body>
            <Card.Title className="text-center">Kiitos vastauksistasi. Kyselyn vastaukset on nyt talletettu</Card.Title>
          </Card.Body>
        </Card>
		<Card className="mb-3">
          <Card.Body>
            <Card.Title className="text-center">Thank you for your answers. Your entries are now stored.</Card.Title>
          </Card.Body>
        </Card>
		<Card className="mb-3">
          <Card.Body>
            <Card.Title className="text-center">Nüüd olete kõigile küsimustele vastanud. Aitäh vastuste eest. Nüüd saate küsitluse sulgeda</Card.Title>
          </Card.Body>
        </Card>
		<Card className="mb-3">
          <Card.Body>
            <Card.Title className="text-center">Tagad jūs esat atbildējis uz visiem jautājumiem. Paldies par atbildēm. Tagad varat aizvērt aptauju</Card.Title>
          </Card.Body>
        </Card>
		<Card className="mb-3">
          <Card.Body>
            <Card.Title className="text-center">Dabar jūs atsakėte į visus klausimus. Dėkojame už atsakymus. Dabar galite uždaryti apklausą</Card.Title>
          </Card.Body>
        </Card>
		</>
		
	)
}

function Error({error}) {
	return (
		<>
		<h3 className="pt-3 text-center">{error}</h3>
		<Card className="mb-3">
          <Card.Body>
            <Card.Title className="text-center">Tämä kysely on päättynyt</Card.Title>
          </Card.Body>
        </Card>
		{ false && <Card className="mb-3">
          <Card.Body>
            <Card.Title className="text-center">Nüüd olete kõigile küsimustele vastanud. Aitäh vastuste eest. Nüüd saate küsitluse sulgeda</Card.Title>
          </Card.Body>
        </Card> }
		{ false && <Card className="mb-3">
          <Card.Body>
            <Card.Title className="text-center">Tagad jūs esat atbildējis uz visiem jautājumiem. Paldies par atbildēm. Tagad varat aizvērt aptauju</Card.Title>
          </Card.Body>
        </Card> }
		{ false && <Card className="mb-3">
          <Card.Body>
            <Card.Title className="text-center">Dabar jūs atsakėte į visus klausimus. Dėkojame už atsakymus. Dabar galite uždaryti apklausą</Card.Title>
          </Card.Body>
        </Card> }
		</>
		
	)
}


export default function QuestionnaireView() {
	
	const params = useParams()
	const dispatch = useDispatch()
	const token = useSelector((state) => state.authorization.token)
	const question = useSelector((state) => state.question.question)
	const error = useSelector((state) => state.question.error)
	const language = useSelector((state) => state.question.language)
	
	useEffect(() => {
		dispatch({type:'authorization/login', payload: { token: params.token, scope: 'survey' }})
	}, [])
	
	useEffect(() => {
		if(token != null) {
			dispatch(fetchQuestion(token, null))
		}
	}, [token])
	
	const onNext = ({answerId, result, comment, nextId}) => {
		dispatch(postAnswer(token, null, answerId, result, comment, nextId))
	}

	const show_spin = question == null && error == null
	const show_error = error !== null
	const show_question = question !== null && question.answerId != 0
	const show_thanks = question !== null && question.answerId == 0
	
	return (
		<Container>
			<div className="pt-5 text-center">
      		<img className="d-block mx-auto mb-4" src="/hrcilogo.svg" width="171" height="78"/>
			</div>
			{ show_spin && <div className="text-center"><Spinner animation="grow" /></div> }
			{ show_question && <QuestionnaireContent question={question} language={language} onNext={onNext} /> }
			{ show_thanks && <ThankYou /> }
			{ show_error && <Error error={error} /> }
		</Container>
	)
}