import react, { useState } from 'react'
import { Modal, Container, Row, Col, Button, FloatingLabel, Form } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { login } from '../reducers/authorizationslice'
import { postPasswordByLink } from '../actions'


export function PasswordResetModal() {
	
	const dispatch = useDispatch()
	const token = useSelector((state) => state.authorization.token)
	const error = useSelector((state) => state.authorization.error)
	const [ newPass1, setNewPass1 ] = useState('');
	const [ newPass2, setNewPass2 ] = useState('');
	const params = useParams()	

	const typeNewPass1 = (evt) => {
		setNewPass1(evt.target.value);
	}

	const typeNewPass2 = (evt) => {
		setNewPass2(evt.target.value);
	}
	
	const pressChange = () => {
		dispatch(postPasswordByLink(params.url, newPass1, newPass2));
	}

	return (
		<Modal show={true} backdrop="static">
		<Modal.Header>Reset Password</Modal.Header>
		<Modal.Body className="bg-light">
	      	<FloatingLabel controlId="newPass1" label="New Password" className="mb-3"  >
    			<Form.Control type="password" value={newPass1} onChange={typeNewPass1} />
	      	</FloatingLabel>
	      	<FloatingLabel controlId="newPass2" label="Repeat New Password" className="mb-3"  >
    			<Form.Control type="password" value={newPass2} onChange={typeNewPass2} />
	      	</FloatingLabel>
	      	{ error && <h4 style={{'color': 'red'}} className="text-center mb-4">Login incorrect</h4> }
		</Modal.Body>
		<Modal.Footer>
			<Button variant="primary" disabled={newPass1 != newPass2} onClick={pressChange} >Change</Button>
		</Modal.Footer>
		</Modal>
	)
	
}

export default function PasswordResetView() {

	return(
		<PasswordResetModal />
	)
}