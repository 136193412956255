import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Form, ListGroup } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux'

import { Menu } from "../menu"
import { LoginModal } from '../loginview'
import { ParticipantModal } from './participantmodal'
import { ConfirmationModal } from '../common/confirmationmodal'
import { SelectCompany, ListGroupCompany } from './companylist'
import { SurveyList } from './surveylist'
import { SurveyModal } from './surveymodal'

import { postSurveyStatus, postParticipantEmailSent, postSurveyStart, postSurveyStop } from '../actions'

export default function DashboardView({admin}) {
	
	const dispatch = useDispatch()
	const token = useSelector((state) => state.authorization.token)
	const companies = useSelector((state) => state.dashboard.companies)
	const companyId = useSelector((state) => state.dashboard.companyId)

	const [startWaitingConfirmation, setStartWaitingConfirmation ] = useState(null);
	const [stopWaitingConfirmation, setStopWaitingConfirmation ] = useState(null);

	useEffect(() => {
		if(token !== null) {
			dispatch(postSurveyStatus(token, companyId))
		}
	}, [token])
	
	const onSelect = (companyId) => {
		dispatch(postSurveyStatus(token, companyId))
	}
	
	const postParticipantEmail = (surveyId) => {
		dispatch(postParticipantEmailSent(token, companyId, surveyId, null))
	}
	
	const onStart = (surveyId) => {
		setStartWaitingConfirmation(surveyId);
	}
	
	const onStop = (surveyId) => {
		setStopWaitingConfirmation(surveyId);
	}
	
	const onStartConfirmed = () => {
		dispatch(postSurveyStart(token, companyId, startWaitingConfirmation))
		setStartWaitingConfirmation(null);
	}
	
	const onStopConfirmed = () => {
		dispatch(postSurveyStop(token, companyId, stopWaitingConfirmation));
		setStopWaitingConfirmation(null);
	}
	
	const onModify = (survey) => {
		dispatch({ type: 'dashboard/editsurvey', payload: survey })
	}

	return (
		<>
		<Menu />
		<LoginModal />
		<ParticipantModal />
		<SurveyModal />
		<ConfirmationModal show={startWaitingConfirmation != null} title="Start Survey" text="Confirm starting the survey" onCancel={() => setStartWaitingConfirmation(null)} onOk={onStartConfirmed} />
		<ConfirmationModal show={stopWaitingConfirmation != null} title="Stop Survey" text="Confirm ending the survey" onCancel={() => setStopWaitingConfirmation(null)} onOk={onStopConfirmed} />
		<Container>
			{ token && admin === true &&
				<>
			 	<Row>
				<Col className="d-xs-block d-lg-none mb-2" xs={12}>
					<SelectCompany companies={companies} companyId={companyId} onSelect={onSelect} />
				</Col>
				</Row>
				<Row>
				<Col className="d-none d-lg-block" lg={3}>
					<ListGroupCompany companies={companies} companyId={companyId} onSelect={onSelect} />
				</Col>
				<Col xs={12} lg={9}>
					<SurveyList admin={true} onStart={onStart} onStop={onStop} onModify={onModify} postParticipantEmail={postParticipantEmail} />
 				</Col>
				</Row>
				</> }
			{ token && admin === false && (<Row><Col><SurveyList admin={false} onStart={onStart} onStop={onStop} onModify={onModify} postParticipantEmail={postParticipantEmail} /></Col></Row>) }
			
		</Container>
		</>
	);
}