import react, { useEffect, useCallback } from 'react'
import { Container, Card, Button } from "react-bootstrap";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

export default function Company() {
	
	return (
		<Container>
			<div className="py-5 text-center">
			<img className="d-block mx-auto mb-4" src="/Sponsor_Logo.jpg" alt="" width="161" height="51" />
			<h2>Yritystiedot</h2>
			</div>
			<FloatingLabel controlId="fcompany" label="Yrityksen nimi" className="mb-3" >
        <Form.Control type="text" placeholder="Yritys Oy" />
      </FloatingLabel>
      <FloatingLabel controlId="fkeyuser" label="Pääkäyttäjän sähköpostiosoite" className="mb-3"  >
        <Form.Control type="email" placeholder="user@company.com" />
      </FloatingLabel>
	  <FloatingLabel controlId="fsize" label="Kokoluokka" className="mb-3" >
      <Form.Select aria-label="Kokoluokka">
        <option disabled>Valitse kokoluokka</option>
        <option value="1">alle 50 työntekijää</option>
        <option value="2">alle 500 työntekijää</option>
        <option value="3">alle 1000 työntekijää</option>
        <option value="4">alle 5000 työntekijää</option>
        <option value="5">5000 tai enemmän työntekijöitä</option>
      </Form.Select>
    </FloatingLabel>
	  <FloatingLabel controlId="ftol" label="Toimiala" className="mb-3" >
      <Form.Select aria-label="Kokoluokka">
        <option disabled>Valitse toimiala</option>
        <option value="1">Kuljetus</option>
        <option value="2">Valmistus</option>
        <option value="3">Puhtaanapito</option>
        <option value="4">IT-ala</option>
        <option value="5">Muu laillinen liiketoiminta</option>
      </Form.Select>
    </FloatingLabel>
	  <FloatingLabel controlId="flocation" label="Sijainti" className="mb-3" >
      <Form.Select aria-label="Kokoluokka">
        <option disabled>Valitse sijainti</option>
        <option value="1">Pääkaupunkiseutu, Suomi</option>
        <option value="2">Muu Suomi</option>
        <option value="3">Ulkomaat</option>
      </Form.Select>
    </FloatingLabel>
		</Container>
	);
}