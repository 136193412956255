import React from 'react';
import { Badge, Form, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux'

export function StatusBadge({task}) {
	const state = task.state == null ? 0 : task.state;
	const badgevariant = state > 98 ? 'success' : state < 20 ? 'danger' : 'warning';
	const badgetext = state.toString() + '%'
	
	return <Badge bg={badgevariant}>{badgetext}</Badge>;
}

export function UpdatedBadge({task}) {

	return <>{ task.updated && <Badge pill bg='warning'>!</Badge>}</>
}



export function StatusRange({task}) {
	
	const state = task.state == null ? 0 : task.state;

	if(state == 0) {
		return <Badge bg='danger'>NOT STARTED</Badge>;
	} else if(state == 100) {
		return <Badge bg='success'>FINISHED</Badge>;
	} else {
		return <Form.Range disabled readOnly value={state} />
	}
}

export function responsibleName(task) {
	const responsibles = useSelector((state) => state.responsible.responsibles)
	
	if(responsibles == null) {
		return '...';
	} else if(task == null) {
		return '...';
	} else {
		const resp = responsibles.find(r => r.value == task.responsible);
		const responsible = resp == null ? { name: '...'} : resp;
		return responsible.name
	}	
}


export function Responsible({task}) {
	const responsibles = useSelector((state) => state.responsible.responsibles)

	if(responsibles == null) {
		return <Spinner animation='grow' />		
	} else if(task == null) {
		return <Spinner animation='grow' />		
	} else {
		const resp = responsibles.find(r => r.value == task.responsible);
		const responsible = resp == null ? '...' : resp.name;
		return <>{responsible}</>
	}
}

export function ResponsibleOptions() {
	const responsibles = useSelector((state) => state.responsible.responsibles)
	
	if(responsibles == null) {
		return <option disabled key={1}>...</option>
	} else {
		return responsibles.map((responsible) => {
			return <option key={responsible.value} value={responsible.value}>{responsible.name}</option>
		})
	}
}
