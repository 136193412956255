import { ApiClient } from './client'

export function postReport(token, surveyId, compareId, language) {
	const client = ApiClient(token);
	const params = {
		surveyId: surveyId,
		compareId: compareId,
		language: language
	}
	return client.post('/wh/report', params)
}

export function postReportDetails(token, surveyId, language) {
	const client = ApiClient(token);
	const params = {
		surveyId: surveyId,
		language: language 
	}
	return client.post('/wh/report/details', params)
}
