import React, { useEffect, useReducer } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, Form, Row, Col, Button, Spinner } from "react-bootstrap"
import { DatePicker } from 'react-widgets';
import { parseDate} from '../common/parsedate';
import { postProject } from '../actions'
import { ResponsibleOptions } from './misc'


function initialTaskState(task) {
	const begindate = task == null || task.begin == null ? new Date() : new Date(task.begin);
	const deadlinedate = task == null || task.deadline == null ? new Date() : new Date(task.deadline);

	return {
		projectId: task == null || task.projectId == null ? null : task.projectId,
		companyId: task == null || task.companyId == null ? null : task.companyId,
		parentId: task == null || task.parentId == null ? null : task.parentId,
		name: task == null || task.name == null ? '' : task.name,
		begin:  begindate,
		deadline: deadlinedate,
		responsible: task == null || task.responsible == null ? '0' : task.responsible,
		responsibleEmail: '',
		responsibleUsername: '',
		description: task == null || task.description == null ? '' : task.description,
		namevalid: false,
		responsiblevalid: false,
		responsibleEmailvalid: false,
		responsibleUsernamevalid: false,
		descriptionvalid: false,
		valid: false,
		validation: false
	};
}

function taskReducer(state, action) {

	const newstate = isValid({ ...state, ...action.payload});
	
	/* console.log('taskReducer', action.payload, newstate); */
	
	return newstate;	
}

function isValid(state) {
	const namevalid = state.name == null ? false : (state.name.length > 5 && state.name.length < 250);
	const responsiblevalid = !(state.responsible == null || state.responsible == '0');
	const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
	const responsibleEmailvalid = state.responsibleEmail == null ? false : state.responsibleEmail.match(validEmailRegex);
	const responsibleUsernamevalid = state.responsibleUsername == null ? false : state.responsibleUsername.length > 2;
	const descriptionvalid = state.description == null ? false : (state.description.length > 5 && state.description.length < 1000);
	const valid = namevalid && responsiblevalid && descriptionvalid &&  
			(state.responsible != '-1' || (responsibleEmailvalid && responsibleUsernamevalid ));

	return { ...state, 
		namevalid: namevalid,
		responsiblevalid: responsiblevalid,
		responsibleEmailvalid: responsibleEmailvalid,
		responsibleUsernamevalid: responsibleUsernamevalid,
		descriptionvalid: descriptionvalid,
		valid: valid
	};
}


function TaskDetail({state, dispatch}) {
	return (
		<Form>
			<Form.Group className="mb-3" controlId="project-name">
        		<Form.Label>Tehtävän nimi</Form.Label>
        		<Form.Control type="text" value={state.name} isInvalid={state.validation && !state.namevalid} onChange={(e) => dispatch({type: 'name', payload: {name: e.target.value}})} />
        		<Form.Control.Feedback type="invalid">Nimi on liian lyhyt, liian pitkä tai puuttuu</Form.Control.Feedback>
      		</Form.Group>

			<Row>
			<Col>
			<Form.Group className="mb-3" controlId="project-begin">
        		<Form.Label>Aloitus</Form.Label>
				<DatePicker parse={parseDate} value={state.begin} onChange={(e) => dispatch({type: 'begin', payload: {begin: e}})}/>
      		</Form.Group>
      		</Col>
      		<Col>

			<Form.Group className="mb-3" controlId="project-deadline">
        		<Form.Label>Tavoiteaika</Form.Label>
				<DatePicker parse={parseDate} value={state.deadline} onChange={(e) => dispatch({type: 'deadline', payload: {deadline: e} })}/>
      		</Form.Group>
      		</Col>
      		</Row>

			<Form.Group className="mb-3" controlId="project-team">
        		<Form.Label>Vastuuhenkilö</Form.Label>
        		{ false && <Form.Control type="text" value={state.responsible} onChange={(e) => dispatch({type: 'responsible', payload: {responsible: e.target.value}})} /> }
        		<Form.Select value={state.responsible} isInvalid={state.validation && !state.responsiblevalid}   onChange={(e) => dispatch({type: 'responsible', payload: {responsible: e.target.value}})}>
        			{ state.responsible === '0' && <option key="0" value="0">...valitse vastuuhenkilö...</option> }
        			<ResponsibleOptions />
        			<option key="-1" value="-1">...lisää uusi henkilö...</option>
        		</Form.Select>
        		<Form.Control.Feedback type="invalid">Vastuuhenkilö puuttuu</Form.Control.Feedback>
      		</Form.Group>

			{ state.responsible == '-1' && <div className="mb-3" style={{border: '2px dotted var(--bs-gray-400)', borderRadius: '0.375rem', padding: '0.375rem 0.75rem'}}> 

			<Form.Group className="mb-3" controlId="project-problem">
        		<Form.Label>Lisättävän vastuuhenkilön nimi</Form.Label>
        		<Form.Control type="text" value={state.responsibleUsername} isInvalid={state.validation && !state.responsibleUsernamevalid}  onChange={(e) => dispatch({type: 'responsibleUsername', payload: {responsibleUsername: e.target.value}})} />
        		<Form.Control.Feedback type="invalid">Nimi puuttuu tai on liian lyhyt</Form.Control.Feedback>
      		</Form.Group>

			<Form.Group className="mb-3" controlId="project-problem">
        		<Form.Label>Lisättävän vastuuhenkilön sähköpostiosoite</Form.Label>
        		<Form.Control type="text" value={state.responsibleEmail} isInvalid={state.validation && !state.responsibleEmailvalid}  onChange={(e) => dispatch({type: 'responsibleEmail', payload: {responsibleEmail: e.target.value}})} />
        		<Form.Control.Feedback type="invalid">Sähköpostiosoite on väärän muotoinen</Form.Control.Feedback>
      		</Form.Group>

			</div> } 


			<Form.Group className="mb-3" controlId="project-problem">
        		<Form.Label>Tehtävän kuvaus</Form.Label>
        		<Form.Control type="text" as="textarea" rows={2} value={state.description} isInvalid={state.validation && !state.descriptionvalid}  onChange={(e) => dispatch({type: 'description', payload: {description: e.target.value}})} />
        		<Form.Control.Feedback type="invalid">Tehtävän kuvaus on liian lyhyt, liian pitkä tai puuttuu</Form.Control.Feedback>
      		</Form.Group>
      		
      	</Form>
      );
}


function TaskModalBody({show, onClose, title, project, task}) {
	
  	const [state, reactDispatch] = useReducer(taskReducer, initialTaskState(null));
 	const token = useSelector((state) => state.authorization.token)
 	const dispatch = useDispatch()

	const okDisabled = state.validation && !state.valid;

  	useEffect(() => {
		if(project != null) {
			reactDispatch({type: 'state', payload: initialTaskState(task)});
		} else {
			reactDispatch({type: 'init', payload: initialTaskState(null)});
		}
	}, [task])

	const onSave = () => {
		if(state.valid) {
			const params = {
				...state,
				begin: state.begin.toISOString().split("T")[0],
				deadline: state.deadline.toISOString().split("T")[0],
			}
			/* console.log('task.onSave', params) */
			dispatch(postProject(token, params));
			onClose();
		} else {
			reactDispatch({type:'validation', payload: {validation: true} });
		}
	}

	
	return (
		<Modal show={show} size="lg" onHide={onClose}>
		<Modal.Header closeButton>
			<Modal.Title>{title}</Modal.Title>
        </Modal.Header>
		<Modal.Body className="bg-light">
			<TaskDetail state={state} dispatch={reactDispatch} />
		</Modal.Body>
		<Modal.Footer>
		<Button variant="secondary" onClick={onClose} >Cancel</Button>
		<Button disabled={okDisabled} onClick={onSave} >OK</Button>
		</Modal.Footer>

		</Modal>
	)
}


export function TaskModal() {
	
	const edit = useSelector((state) => state.task.edit)
	const project = useSelector((state) => state.project.selected)
	const responsibles = useSelector((state) => state.project.responsibles)
	const dispatch = useDispatch()
	
	const show = edit != null;
	const title = edit != null && edit.taskId != null ? 'Muokkaa tehtävää' : 'Uusi tehtävä';
	
	
	return <TaskModalBody show={show} project={project} task={edit} responsibles={responsibles} onClose={() => dispatch({type: 'task/edit', payload: null})} title={title}/>
}
