import React, { useEffect} from "react"
import { Container, Form, Row, Col, Accordion, Badge, Table, Button, Spinner } from "react-bootstrap"


export function StartedSurvey({admin, companyId, survey, onClickSend, onStopSurvey}) {
	const id = survey.surveyId
	const participants = survey.participantsFinished + '/' + survey.participantsAll
	const timerange = survey.startDate + ' - ' + survey.deadLineDate
	
	return(
	<>
		<Row className="mb-2">
		<Form.Group as={Col} controlId={'name-'+id}>
			<Form.Label>Nimi</Form.Label>
			<Form.Control value={survey.name} readOnly />		
		</Form.Group>
		<Form.Group as={Col} controlId={'deadline-'+id}>
			<Form.Label>Ajankohta</Form.Label>
			<Form.Control value={timerange} readOnly />		
		</Form.Group>
		</Row>
		<Row className="mb-4">
		<Form.Group as={Col} controlId={'participants-'+id}>
			<Form.Label>Osallistujia</Form.Label>
			<Form.Control value={participants} readOnly />		
		</Form.Group>
	</Row>
	<Row>
		<Col>
		{ survey.emailsNotSent > 0 &&
		<Form.Group controlId={'not-sent-'+id}>
			<Form.Label>Linkkejä lähettämättä</Form.Label>
			<Form.Control value={survey.emailsNotSent} readOnly />		
		</Form.Group>
		}
		</Col>
		<Col>
		{ survey.emailsNotSent > 0 &&
		<Form.Group controlId={'links-'+id}>
			<Form.Label style={{color: 'white'}}>Lähetä</Form.Label>
			<Form.Control onClick={() => onClickSend(survey.surveyId) } as={Button}>Lähetä</Form.Control>		
		</Form.Group>
		}
		</Col>
		<Col>
		{ admin &&
		<Form.Group controlId={'stop-'+id}>
			<Form.Label style={{color: 'white'}}>Stop</Form.Label>
			<Form.Control variant="danger" onClick={() => onStopSurvey(survey.surveyId) } as={Button}>Päätä kysely</Form.Control>		
		</Form.Group>
		}
		</Col>
	</Row>
	</>
	);
}
