import { createSlice } from '@reduxjs/toolkit'

export const questionSlice = createSlice({
	name: 'question',
	initialState: {
		question: null,
		error: null
	},
	reducers: {
		store: (state, action) => {
			state.question = action.payload
			state.error = null
		},
		result: (state, action) => {
			state.question.result = action.payload
		},
		wait: (state, action) => {
			state.question = null
			state.error = null
		},
		error: (state, action) => {
			state.question = null
			state.error = action.payload
		} 
	}
})

export const { store, result, wait, error } = questionSlice.actions;

export default questionSlice.reducer