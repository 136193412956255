import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, ListGroup, Button, Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux'

import { Menu } from "../menu"
import { LoginModal } from '../loginview'

import { SelectCompany } from '../dashboardview/companylist'
import { ProjectModal } from './projectmodal'
import { TaskModal } from './taskmodal'
import { postSurveyStatus, getProject, getResponsibles } from '../actions'
import { ProjectDetails} from './projectdetails'
import { StatusBadge, UpdatedBadge } from './misc'
import { TaskDetails, OwnTasks } from './taskdetails'


export default function ProjectView() {

	const dispatch = useDispatch()
	const token = useSelector((state) => state.authorization.token)
	const companies = useSelector((state) => state.dashboard.companies)
	const companyId = useSelector((state) => state.dashboard.companyId)
	const scope = useSelector((state) => state.authorization.scope)
	const selectedProject = useSelector((state) => state.project.selected)
	const selectedTask = useSelector((state) => state.task.selected)
	const admin = scope === 'admin';

	useEffect(() => {
		if(token !== null) {
			dispatch(postSurveyStatus(token, companyId))
		}
	}, [token])
	
	const onSelect = (companyId) => {
		dispatch(postSurveyStatus(token, companyId))
	}

	return (
		<>
		<Menu />
		<LoginModal />
		<ProjectModal />
		<TaskModal />
		<Container>
			{ admin === true &&
				<>
			 	<Row>
				<Col className="mb-2">
					<SelectCompany companies={companies} companyId={companyId} onSelect={onSelect} />
				</Col>
				</Row>
				</>
			}
			{ companyId != null && <DoubleSelection selectedProject={selectedProject} selectedTask={selectedTask} /> }
		</Container>
		</>
	);
}


function DoubleSelection({selectedProject, selectedTask}) {
	const transition = {
		transition: 'width 0.1s ease-out',
	}
	
	const taskClosed = selectedTask == null ? true : (selectedTask.tasks != null);
	const taskOpened = !taskClosed;

	const [left, right] = selectedProject == null ? (taskClosed ? [6,6] : [9,3]) : [3,9]

	return (
		<Row>
		<Col style={transition} xs={left}>
			{ taskClosed && <ProjectList /> }
			{ taskOpened && <TaskDetails task={selectedTask} /> }
		</Col>
		<Col style={transition} xs={right}>
			{ selectedProject == null && <OwnTasks /> }
			{ selectedProject != null && <ProjectDetails /> }
		</Col>
		</Row>
	); 
}


function ProjectList() {
	
	const token = useSelector((state) => state.authorization.token)
	const companyId = useSelector((state) => state.dashboard.companyId)
	const projects = useSelector((state) => state.project.projects)	
	const selected = useSelector((state) => state.project.selected)
	const dispatch = useDispatch()

	useEffect(() => {
		if(token !== null) {
			dispatch(getProject(token, companyId))
			dispatch(getResponsibles(token, companyId))
		}
	}, [token, companyId])


	
	const namestyle = {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis'
	}
	
	if(companyId === null) {
		return (<></>);
	} else if(projects === null) {
		return (<Spinner animation='grow' />)
	} else {
		const pid = selected == null ? null : selected.projectId
		return (
			<>
			<Card>
			<Card.Header>Projektit</Card.Header>
			<ListGroup variant='flush' className="mb-2">
				{ projects.map(project =>  {
					const id = project.projectId;
					const tbsel = pid == id ? null : project;
					return (
						<ListGroup.Item action active={pid == id} key={id} onClick={() => dispatch({type: 'project/select', payload: tbsel})} style={namestyle}>
						<Container>
						<Row >
							<Col style={namestyle} className="me-auto"><UpdatedBadge task={project}/> {project.name}</Col>
							<Col className='pe-1' xs='auto'><StatusBadge task={project} /></Col>
						</Row>
						</Container>
						</ListGroup.Item>
					);
				})}
			</ListGroup>
			</Card>
			<Button className="my-3 ms-2 me-auto" size="sm" onClick={() => dispatch({type: 'project/edit', payload: {companyId: companyId}})} >Lisää projekti</Button>
			</>

		)
	}
}



