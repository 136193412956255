import React, { useEffect} from "react"
import { Container, Row, Col, Accordion, Button, Spinner } from "react-bootstrap"
import { useSelector, useDispatch } from 'react-redux'

import { Menu } from "../menu"
import { LoginModal } from '../loginview'
import { getUsers, postPasswordReset } from '../actions'
import { PasswordResetModal } from './passwordresetmodal'

export default function AdminUserView() {

	const dispatch = useDispatch()
	const token = useSelector((state) => state.authorization.token)
	const users = useSelector((state) => state.admin.users)

	useEffect(() => {
		if(token !== null) {
			dispatch(getUsers(token))
		}
	}, [token])
	
	const getPasswordResetLink = (adminId) => {
		dispatch(postPasswordReset(token, adminId));
	}

	const companylist = users === null ? [] : users;

	return (
	<>
		<Menu />
		<LoginModal />
		<PasswordResetModal />
		<Container>
		<Row>
		<h4>Users</h4>
		</Row>
		{ users === null && <Spinner animation="grow" />}
		<Accordion defaultActiveKey={0} flush >
		{companylist.map(company => {
			const admins = company.admins;
			return (
				<Accordion.Item key={company.companyId} eventKey={company.companyId}>
				<Accordion.Header><div className="me-auto">{company.name}</div></Accordion.Header>
				<Accordion.Body>
				<Container>
				{admins.map(admin => {
					return (<Row className="mb-1" key={admin.adminId}><Col>{admin.email}</Col><Col xs="auto"><Button onClick={() => getPasswordResetLink(admin.adminId)} size="sm" bg='danger'>Link for Password Reset</Button></Col></Row>);
				})}
				</Container>
				</Accordion.Body>
				</Accordion.Item>
			);
		})}
		</Accordion>
		</Container>
	</>
	)
}	
