import react, { useState } from 'react'
import { Modal, Alert, Container, Row, Col, Button, FloatingLabel, Form } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { login } from '../reducers/authorizationslice'
import { postLogin } from '../actions'


export function LoginModal() {
	
	const dispatch = useDispatch()
	const token = useSelector((state) => state.authorization.token)
	const error = useSelector((state) => state.authorization.error)
	const [ username, setUsername ] = useState('');
	const [ password, setPassword ] = useState('');

	const pressLogin = () => {
		dispatch(postLogin(username, password));
	}

	return (
		<Modal show={token==null} backdrop="static">
		<Modal.Header  className="bg-light">
		<img className="d-block mx-auto" src="/hrcilogo.svg" width="171" height="78"/>
		</Modal.Header>
		<Modal.Body className="bg-light">
	      	<FloatingLabel controlId="username" label="Username" className="mb-3"  >
    			<Form.Control type="email" value={username} onChange={evt => setUsername(evt.target.value)} placeholder="user@company.com" />
	      	</FloatingLabel>
	      	<FloatingLabel controlId="password" label="Password" className="mb-3"  >
    			<Form.Control type="password" value={password} onChange={evt => setPassword(evt.target.value)} placeholder="password" />
	      	</FloatingLabel>
	      	{ error && <h4 style={{'color': 'var(--bs-danger)'}}className="text-center mb-4">Login incorrect</h4> }
		</Modal.Body>
		<Modal.Footer>
			<Button onClick={pressLogin} active className="w-100">Log in</Button>
		</Modal.Footer>
		</Modal>
	)
	
}