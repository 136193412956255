import { ApiClient } from './client'


export function getQuestions(token, language, variant) {
	const client = ApiClient(token);
	const path = language == null && variant == null ? '/rest/admin/question' : '/rest/admin/question/' + language + '/' + variant
	return client.get(path);
}

export function postQuestion(token, topic) {
	const client = ApiClient(token);
	return client.post('/rest/admin/question', topic);	
}
