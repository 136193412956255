import { ApiClient } from './client'
export * from './adminapi'
export * from './companyapi'
export * from './surveyapi'
export * from './reportapi'
export * from './dashboardapi'
export * from './questionmgmtapi'
export * from './projectapi'

export function postLogin(username, password) {
	const client = ApiClient(null);
	const params = {
		username: username,
		password: password
	}
	return client.post('/token',params);
}

export function postPassword(token, oldPassword, newPassword1, newPassword2) {
	const client = ApiClient(token);
	const params = {
		oldPassword : oldPassword,
		newPassword1: newPassword1,
		newPassword2: newPassword2
	}
	return client.post('/password',params);
}
