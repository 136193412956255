import React, { useRef, useEffect } from "react"
import * as d3 from "d3"


export function RemoveAxis(svgRef) {
	const yaxis = d3.select(svgRef.current).select("g.yaxis");
	const xaxis = d3.select(svgRef.current).select("g.xaxis");
	const gdata = d3.select(svgRef.current).select("g.data");

	yaxis.selectAll("g").data([])
	.join(
		enter => {},
		update => {},
		exit => 
			exit.remove());
          
	xaxis.selectAll("g").data([])
	.join(
		enter => {},
		update => {},
		exit => 
			exit.remove());

	gdata.selectAll("path").data([])
		.join(
			enter => {},
			update => {},
			exit => exit
				.transition(d3.easeLinear)
				.duration(300)
				.style("opacity", 0)
				.remove());

	gdata.selectAll("rect").data([])
		.join(
			enter => { },
			update => { },
			exit =>	exit.remove());

}

export function DrawYAxis(svgRef, data, innerRadius, outerRadius) {

	const yaxis = d3.select(svgRef.current).select("g.yaxis");
	
  	const y = d3.scaleLinear()
    .domain([1, 10])
    .range([innerRadius, outerRadius])	

	yaxis
		.attr("fill", "none")
		.selectAll("circle")
		.data(y.ticks(5).reverse())
		.join(
			enter => { enter.append("circle").attr("stroke", "#000").attr("stroke-opacity", 0.2).attr("r", y); },
			update => { update.transition(d3.easeLinear).duration(1300).attr("stroke", "#000").attr("stroke-opacity", 0.2).attr("r", y); },
			exit => { exit.transition(d3.easeLinear).duration(300).style("opacity", 0).remove(); }
		);
		
	yaxis
		.attr("text-anchor", "middle")
    	.attr("font-family", "sans-serif")
    	.attr("font-size", 10)
		.selectAll("text")
		.data(y.ticks(5).reverse())
		.join(
			enter => { 
				enter.append("text").attr("y", d => y(d)).attr("dy", "0.35em").attr("stroke", "#fff").attr("fill", "none").attr("stroke-width", 5).text((x, i) => `${x.toFixed(0)}`);
				enter.append("text").attr("y", d => y(d)).attr("dy", "0.35em").attr("stroke", "none").attr("fill", "currentColor").attr("stroke-width", 5).text((x, i) => `${x.toFixed(0)}`);
			},				
			update => { },
			exit => {  }
		);    	
		
}

export function DrawXAxisDetails(svgRef, refname, indata, innerRadius, outerRadius) {
	
		const data = indata.map((value, inx) => {
		const text = value.title + ' (n=' + (value.n-value.zeroes) +')'; 
		return ({ ...value, title: text }); 
	});
	

	DrawXAxis(svgRef, refname, data, innerRadius, outerRadius);
}

export function DrawXAxis(svgRef, refname, indata, innerRadius, outerRadius) {

	const data = indata.map((value, inx) => { return({ ...value , id: inx }) }); 
	
	const x = d3.scaleLinear()
  	.domain([0,data.length])
    .range([0, 2 * Math.PI]);
	
	const xaxis = d3.select(svgRef.current).select("g.xaxis");
	
	xaxis
   		.selectAll("path")
      	.data(data)
		.join(
			enter => { 
				enter.append("path")
            		.attr("stroke", "#000")
            		.attr("stroke-opacity", 0.2)
            		.attr("id", d => `${refname}-${d.id}`)
            		.attr("d", d => {
						const rad = x(d.id); 
            			const bg = d3.pointRadial(rad, innerRadius);
            			const ed = d3.pointRadial(rad, outerRadius);
            			if(rad > Math.PI) {
            				return (`M${ed} L${bg}`);
							
						} else {
            				return (`M${bg} L${ed}`);
            			}
            		});
			},				
			update => {
				update
					.attr("stroke", "#000")
            		.attr("stroke-opacity", 0.2)
            		.attr("id", d => `${refname}-${d.id}`)
            		.attr("d", d => {
						const rad = x(d.id); 
            			const bg = d3.pointRadial(rad, innerRadius);
            			const ed = d3.pointRadial(rad, outerRadius);
            			if(rad > Math.PI) {
            				return (`M${ed} L${bg}`);
							
						} else {
            				return (`M${bg} L${ed}`);
            			}
            		});
			},
			exit => { exit.transition(d3.easeLinear).duration(300).style("opacity", 0).remove(); }
		);    	

	xaxis
		.attr("font-family", "sans-serif")
   		.attr("font-size", 10)
   		.selectAll("text")
      	.data(data)
		.join(
			enter => {
				const text = enter.append("text").attr("stroke-opacity", 0.2); 
					text.append("textPath").attr("text-anchor", "middle").attr("startOffset", "50%").attr("fill", "none").attr("stroke", "#fff").attr("stroke-width", 5).attr("rotate","180").attr("href", d =>  `#${refname}-${d.id}`).text(d => d.title);
					text.append("textPath").attr("text-anchor", "middle").attr("startOffset", "50%").attr("fill", "currentColor").attr("stroke", "none").attr("stroke-width", 5).attr("rotate","180").attr("href", d =>  `#${refname}-${d.id}`).text(d => d.title);
			 },				
			update => { 
			},
			exit => { 
				exit.transition(d3.easeLinear).duration(300).style("opacity", 0).remove(); 
			}
		);
}



export function DrawData(svgRef, indata, innerRadius, outerRadius) {

    const data = indata.map((value, inx) => {return ({ ...value , id: inx }); });
    const domain = data.map(entry => entry.id);
	const x = d3.scaleLinear()
  	.domain(domain)
    .range([0, 2 * Math.PI /(domain.length)]);
    
    data.push(data[0]);

  	const y = d3.scaleLinear()
    .domain([1, 10])
    .range([innerRadius, outerRadius])	

	
	const outerarea = d3.areaRadial().curve(d3.curveLinearClosed).angle(d => x(d.id)).innerRadius(d => y(d.quartiles[0])).outerRadius(d => y(d.quartiles[4]));

	const innerarea = d3.areaRadial().curve(d3.curveLinearClosed).angle(d => x(d.id)).innerRadius(d => y(d.quartiles[1])) .outerRadius(d => y(d.quartiles[3]));
    
	const line = d3.lineRadial().curve(d3.curveLinearClosed).angle(d => x(d.id)).radius(d => y(d.quartiles[2]));
    
	const gdata = d3.select(svgRef.current).select("g.data");
	
	gdata.selectAll("path").data([])
		.join(null, null, exit => { exit.style("opacity", 0).remove();  });

	gdata.append("path").attr("fill", "#3A307A").attr("fill-opacity", 0.1).attr("d", outerarea(data));
	gdata.append("path").attr("fill", "#3A307A").attr("fill-opacity", 0.1).attr("d", innerarea(data));
	gdata.append("path").attr("fill", "none").attr("stroke", "#3A307A").attr("fill-opacity", 0.1).attr("stroke-width", 2).attr("d", line(data));
 }
 
 export function DrawIndex(svgRef, radius, average) {
	const gindex = d3.select(svgRef.current).select("g.index");
	 
	 gindex.selectAll("g")
	 	.data([average])
	 	.join(
			enter => {
				 const g = enter.append("g");
				 g.attr("text-anchor", "middle").attr("font-family", "sans-serif").attr("font-size", radius);
				 g.append("circle").attr("stroke", "none").attr("fill", "#3A307A").attr("fill-opacity", 0.1).attr("r", radius);
				 g.append("text").attr("y", 0).attr("dy", "0.35em").attr("stroke", "#fff").attr("fill", "none").attr("stroke-width", 5).text(average);
				 g.append("text").attr("y", 0).attr("dy", "0.35em").attr("stroke", "none").attr("fill", "#FF00DB").attr("stroke-width", 5).text(average);

			},
			update => {},
			exit => { exit.remove() }
		 );
 }
