import { createSlice } from '@reduxjs/toolkit'

export const authorizationSlice = createSlice({
	name: 'authorization',
	initialState: {
		token: null,
		scope: null,
		survey_licence: false,
		project_licence: false,
		error: null,
		email: null,
		language: 'fi',
	},
	reducers: {
		login: (state, action) => {
			state.token = action.payload.token
			state.scope = action.payload.scope
			state.survey_licence = action.payload.scope == 's_company' || action.payload.scope == 'sp_company'
			state.project_licence = action.payload.scope == 'p_company' || action.payload.scope == 'sp_company'
			state.error = null
			state.email = action.payload.email
		},
		error: (state, action) => {
			state.error = action.payload
			state.scope = null
			state.survey_licence = false
			state.project_licence = false
			state.token = null
			state.email = null
		},
		logout: (state, action) => {
			state.token = null
			state.scope = null
			state.survey_licence = false
			state.project_licence = false
			state.error = null
			state.email = null
		},
		language: (state, action) => {
			state.language = action.payload.language
		}
	}
})

export const { login, error, logout } = authorizationSlice.actions;

export default authorizationSlice.reducer