import { ApiClient } from './client'


export function postSurveyStatus(token, companyId) {
	const client = ApiClient(token);
	const params = {
		companyId: companyId
	};
	return client.post('/rest/admin/status', params);
}


export function postSurveyStart(token, surveyId) {
	const client = ApiClient(token);
	const params = {
		surveyId: surveyId
	};
	return client.post('/rest/admin/survey/start', params);
}


export function postSurveyStop(token, surveyId) {
	const client = ApiClient(token);
	const params = {
		surveyId: surveyId
	};
	return client.post('/rest/admin/survey/stop', params);
}

export function postEmailAttachment(token, surveyId, contenttype, content) {
	const client = ApiClient(token, { "Content-Type": contenttype })
	return client.post('/rest/admin/survey/' + surveyId + '/emails', content);
}


export function postParticipantEmailSent(token, surveyId, personId) {
	const client = ApiClient(token);
	const params = {
		surveyId: surveyId,
		personId: personId
	}
	return client.post('/rest/admin/survey/url', params)
}


export function postSurvey(token, survey) {
	const client = ApiClient(token);
	return client.post('/rest/admin/survey', survey);
}

