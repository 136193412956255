
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap"
import { useDispatch, useSelector } from 'react-redux'

export function AdminMenu({email}) {
	
	const dispatch = useDispatch()
	const language = useSelector((state) => state.authorization.language)
	
	const language_switch = language == 'fi' ? 'Vaihda englanniksi' : 'Switch to Finnish';
	const payload = { language: language == 'fi' ? 'en' : 'fi' };
	
	const resetSelection = () => {
		dispatch({type: 'project/select', payload: null });
		dispatch({type: 'task/select', payload: null });
		dispatch({type: 'report/select', payload: {surveyId: null, compareId: null }});
		dispatch({type: 'dashboard/selectSurvey', payload: null});
	}

	return (
	<Navbar collapseOnSelect expand="sm" bg="light">
        <Container>
          <Navbar.Brand onClick={resetSelection}>
            <img
              src="/hrcilogo.svg" width="86" height="39"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
          <Nav className="me-auto">
          	<LinkContainer to="/">
            <Nav.Link onClick={() => console.log('AdminMenu.Status')} >Status</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/users">
            <Nav.Link onClick={() => console.log('AdminMenu.Users')}>Users</Nav.Link>
            </LinkContainer>
          	<LinkContainer to="/report">
            <Nav.Link onClick={() => console.log('AdminMenu.Report')}>Report</Nav.Link>
            </LinkContainer>
          	<LinkContainer to="/qmgmt">
            <Nav.Link onClick={() => console.log('AdminMenu.Questions')}>Questions</Nav.Link>
            </LinkContainer>
          	<LinkContainer to="/project">
            <Nav.Link onClick={() => console.log('AdminMenu.Projects')}>Projects</Nav.Link>
            </LinkContainer>
          </Nav>
          <Nav>
          	<NavDropdown align="end" title={email} id="userDropdown">
              <NavDropdown.Item onClick={() => dispatch({type: 'authorization/language', payload: payload })}>{language_switch}</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => dispatch({type: 'password/show'})}>Change Password</NavDropdown.Item>
              <NavDropdown.Divider />
    	      <NavDropdown.Item onClick={() => { window.location.replace("/"); } }>Log out</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          </Navbar.Collapse>
        </Container>
    </Navbar>
	)

}