import { createSlice } from '@reduxjs/toolkit'

export const reportSlice = createSlice({
	name: 'report',
	initialState: {
		selection: null,
		surveyId: null,
		compareId: null,
		name: null,
		average: null,
		result: null,
		l1Id: null,
		details: null
	},
	reducers: {
		store: (state, action) => {
			state.selection = action.payload.selection
			state.surveyId = action.payload.surveyId
			state.compareId = action.payload.compareId
			state.name = action.payload.name
			state.average = action.payload.average
			state.result = action.payload.result
			state.l1Id = null
			state.details = null
		},
		select: (state, action) => {
			state.surveyId = action.payload.surveyId
			state.compareId = action.payload.compareId
			state.name = null
			state.average = null
			state.result = null
			state.details = null
		},
		selectl1: (state, action) => {
			state.l1Id = action.payload
		},
		details: (state, action) => {
			state.details = action.payload.details
		}
	}
})

export const { store, select, selectl1, details } = reportSlice.actions;

export default reportSlice.reducer