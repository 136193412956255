import { createSlice } from '@reduxjs/toolkit'

export const surveySlice = createSlice({
	name: 'survey',
	initialState: {
		surveys: null
	},
	reducers: {
		list: (state, action) => {
			state.surveys = action.payload.surveys
		},
		wait: (state, action) => {
			state.surveys = null
		}
	}
})

export const { list, wait } = surveySlice.actions;

export default surveySlice.reducer