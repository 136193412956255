import React, { useEffect } from 'react'
import { Container, Spinner, Row, Col, Form} from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'

import { getQuestions } from '../actions'
import { Menu } from "../menu"
import { LoginModal } from '../loginview'
import { EditTopicModal } from './edittopicmodal'


export default function QuestionMgmtView() {
	
	const dispatch = useDispatch()
	const token = useSelector((state) => state.authorization.token)
	const questions = useSelector((state) => state.questionMgmt.questions)
	
	useEffect(() => {
		if(token !== null) {
			dispatch(getQuestions(token))
		}
	}, [token])
	
	
	const Question = ({q, idx, idx2, idx3}) => {
		const titleEmpty = q.title === null || q.title.length == 0 
		const title = titleEmpty ? q.titleRef : q.title;
		const className = titleEmpty ? "alert-danger" : "";	
		const style = titleEmpty ? { color: 'var(--bs-alert-color)', backgroundColor: 'var(--bs-alert-bg)'} : {}
		return (
			<Row id={'r3-'+idx+'-'+idx2+'-'+idx3} key={'r3-'+idx+'-'+idx2+'-'+idx3} style={{fontSize: '10px'}} onClick={() => dispatch({ type: 'questionmgmt/edititem', payload: q })}>
				<Col xs={2} />
				<Col style={style} className={className} xs={9}>{title}</Col>
				<Col xs={1} />
			</Row>
		);
	}
	
	const L2Topic = ({l2, idx, idx2}) => {
		const titleEmpty = l2.title === null || l2.title.length == 0 
		const title = titleEmpty ? l2.titleRef : l2.title;
		const className = titleEmpty ? "alert-danger" : "";
		const style = titleEmpty ? { color: 'var(--bs-alert-color)', backgroundColor: 'var(--bs-alert-bg)'} : {}
		return (
			<>
			<Row style={{fontSize: '12px'}} id={'r2-'+idx+'-'+idx2}  key={'r2-'+idx+'-'+idx2} onClick={() => dispatch({ type: 'questionmgmt/edititem', payload: l2 })}>
				<Col xs={1} />
				<Col style={style} className={className} xs={10}>{title}</Col>
				<Col xs={1} />
			</Row>
			{ l2.topics.map((q, idx3) => <Question key={'r3-'+idx+'-'+idx2+'-'+idx3} q={q} idx={idx} idx2={idx2} idx3={idx3} /> )}			
			</>
		);
	}
	
	const LanguageVariantSelector = () => {
		const dispatch = useDispatch()
		const token = useSelector((state) => state.authorization.token)
		const variant = useSelector((state) => state.questionMgmt.variant)
		const language = useSelector((state) => state.questionMgmt.language)

		return (
			<Row className="mb-2">
			<Form.Group size="sm" as={Col} controlId={'variant-1'}>
				<Form.Label size="sm">Laajuus</Form.Label>		
				<Form.Select size="sm" value={variant} onChange={(evt) => dispatch(getQuestions(token, language, evt.target.value))}>
      				<option value="LAAJA">Laaja</option>
      				<option value="SUPPEA">Suppea</option>
    			</Form.Select>
			</Form.Group>
			<Form.Group as={Col} controlId={'language-1'}>
				<Form.Label size="sm">Kieli</Form.Label>
				<Form.Select size="sm"  value={language} onChange={(evt) => dispatch(getQuestions(token, evt.target.value, variant))}>
      				<option value="fi">Finnish</option>
      				<option value="en">English</option>
      				<option value="et">Estonian</option>
      				<option value="lv">Latvian</option>
      				<option value="lt">Lithuanian</option>
    			</Form.Select>
			</Form.Group>
		</Row>
		)
	}
	
	return (
		<>
		<Menu />
		<LoginModal />
		<EditTopicModal />
		<Container style={{fontSize: '14px'}}>
			{ questions === null && <Spinner  animation="grow" /> }
			{ questions !== null && <LanguageVariantSelector  />}
			{ questions !== null && questions.map((l1, idx) => {
				const titleEmpty = l1.title === null || l1.title.length == 0 
				const title = titleEmpty ? l1.titleRef : l1.title;
				const className = titleEmpty ? "alert-danger" : "";	
				const style = titleEmpty ? { color: 'var(--bs-alert-color)', backgroundColor: 'var(--bs-alert-bg)'} : {}
				return ( 
					<React.Fragment key={'r'+idx} >
					<Row onClick={() => dispatch({ type: 'questionmgmt/edititem', payload: l1 })} >
						<Col style={style} className={className} xs={11}>{title}</Col>
						<Col xs={1}></Col>
					</Row>
					{ l1.topics.map((l2,idx2) => <L2Topic id={'r2-'+idx+'-'+idx2} key={'r2-'+idx+'-'+idx2} l2={l2} idx={idx} idx2={idx2} /> )}
					</React.Fragment>
				);
			})}
		</Container>
		</>
	)
}